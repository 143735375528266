import React, { useEffect, useState } from 'react';
import AddUser from './AddUser';
// import UpdateUser from './UpdateUser';
import {
	apiGetAllClient,
	apiGetAllModuleRole,
	// apiGetAllUsersClients,
	apiGetAllUsers,
	IClientBackEnd,
	IModuleRoleBackEnd,
	IUserBackEnd,
	// IUserBackEnd,
} from '../../../services/apiService';
import { toast } from 'react-toastify';
import { MoonLoader } from 'react-spinners';
import UpdateUser from './UpdateUser';

function UserSettings() {
	const [moduleRoleDropdown, setModuleRoleDropdown] = useState<
		IModuleRoleBackEnd[]
	>([]);
	const [users, setUsers] = useState<IUserBackEnd[]>([]);
	const [updatedDataToggle, setUpdatedDataToggle] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const clients = await apiGetAllModuleRole();
				setModuleRoleDropdown(clients);
			} catch (error) {
				toast.error('Erro ao carregar dados dos módulos de visualização.');
			}
			try {
				const users = await apiGetAllUsers();
				setUsers(users);
			} catch (error) {
				toast.error('Erro ao carregar usuários.');
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		};
		fetchData();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const users = await apiGetAllUsers();
				setUsers(users);
			} catch (error) {
				toast.error('Erro ao carregar usuários.');
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		};
		setIsLoading(true);
		fetchData();
	}, [updatedDataToggle]);

	return (
		<div>
			<AddUser
				module_roles={moduleRoleDropdown}
				updatedDataToggle={updatedDataToggle}
				setUpdatedDataToggle={setUpdatedDataToggle}
			/>
			{isLoading ? (
				<div>
					<MoonLoader color="#36d7b7" />
				</div>
			) : (
				<UpdateUser
					users={users}
					module_roles={moduleRoleDropdown}
					updatedDataToggle={updatedDataToggle}
				/>
			)}
		</div>
	);
}

export default UserSettings;
