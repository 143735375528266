import React, { useState, useEffect } from 'react';
import {
	Button,
	Grid,
	MenuItem,
	Paper,
	Select,
	Typography,
	FormControl,
	InputLabel,
	TextField,
	Checkbox,
	FormControlLabel,
} from '@mui/material';
import {
	apiUpdateUser,
	IUserBackEnd,
	IModuleRoleBackEnd,
	IUserUpdate,
} from '../../../services/apiService';
import { toast } from 'react-toastify';

interface UpdateUserProps {
	users: IUserBackEnd[];
	module_roles: IModuleRoleBackEnd[];
	updatedDataToggle: boolean;
}

function UpdateUser({
	users,
	module_roles,
	updatedDataToggle,
}: UpdateUserProps) {
	const [updatedUsers, setUpdatedUsers] = useState<IUserUpdate[]>([]); // Alteração aqui

	useEffect(() => {
		// Incluímos o campo 'password' com valor inicial vazio para cada usuário no estado atualizado
		const sortedUsers = [...users]
			.sort((a, b) => a.username.localeCompare(b.username))
			.map(user => ({
				...user,
				password: '', // Adiciona o campo password vazio
			}));
		setUpdatedUsers(sortedUsers);
	}, [users, updatedDataToggle]);

	const handleUpdateUser = async (user: IUserUpdate) => {
		if (!user.module_role_id) {
			toast.error('A visualização não pode ser nula');
			return;
		}

		try {
			// Envia o password somente se ele for preenchido
			const userData: IUserUpdate = {
				...user,
				password: user.password || null,
			};

			await apiUpdateUser(user.id, userData);
			toast.success('Usuário atualizado com sucesso');
		} catch (error) {
			toast.error('Erro ao atualizar o Usuário');
		}
	};

	const handleNameChange = (id: number, newName: string) => {
		setUpdatedUsers(prevUsers =>
			prevUsers.map(user =>
				user.id === id ? { ...user, username: newName } : user
			)
		);
	};

	const handleModuleRoleChange = (id: number, newModuleRoleId: number) => {
		setUpdatedUsers(prevUsers =>
			prevUsers.map(user =>
				user.id === id ? { ...user, module_role_id: newModuleRoleId } : user
			)
		);
	};

	const handleEmailChange = (id: number, newEmail: string) => {
		setUpdatedUsers(prevUsers =>
			prevUsers.map(user =>
				user.id === id ? { ...user, email: newEmail } : user
			)
		);
	};

	const handlePasswordChange = (id: number, newPassword: string) => {
		setUpdatedUsers(prevUsers =>
			prevUsers.map(user =>
				user.id === id ? { ...user, password: newPassword } : user
			)
		);
	};

	const handleCoordinatorChange = (
		id: number,
		newCoordinatorStatus: boolean
	) => {
		setUpdatedUsers(prevUsers =>
			prevUsers.map(user =>
				user.id === id ? { ...user, coordinator: newCoordinatorStatus } : user
			)
		);
	};

	return (
		<Grid item xs={12}>
			<Paper elevation={5} style={{ padding: 20 }}>
				<Typography
					variant="h6"
					component="h3"
					style={{ marginBottom: '20px' }}
				>
					Usuários
				</Typography>
				{updatedUsers.map(user => (
					<Grid
						container
						spacing={2}
						alignItems="center"
						key={user.id}
						style={{ marginBottom: 20 }}
					>
						<Grid item xs={2}>
							<TextField
								fullWidth
								label="Nome Usuário"
								value={user.username}
								onChange={e => handleNameChange(user.id, e.target.value)}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={2}>
							<TextField
								fullWidth
								label="E-mail"
								value={user.email}
								onChange={e => handleEmailChange(user.id, e.target.value)}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={2}>
							<TextField
								fullWidth
								label="Senha"
								type="password"
								value={user.password || ''}
								onChange={e => handlePasswordChange(user.id, e.target.value)}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={1.5}>
							<FormControl fullWidth variant="outlined">
								<InputLabel id={`module-role-select-label-${user.id}`}>
									Visualização
								</InputLabel>
								<Select
									labelId={`module-role-select-label-${user.id}`}
									value={user.module_role_id ? String(user.module_role_id) : ''}
									onChange={e =>
										handleModuleRoleChange(user.id, Number(e.target.value))
									}
									label="Visualização"
								>
									{module_roles.map(module_role => (
										<MenuItem key={module_role.id} value={module_role.id}>
											{module_role.description}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={1} style={{ paddingRight: '16px' }}>
							<FormControlLabel
								control={
									<Checkbox
										checked={user.coordinator}
										onChange={e =>
											handleCoordinatorChange(user.id, e.target.checked)
										}
										color="primary"
									/>
								}
								label="Coordenador"
							/>
						</Grid>
						<Grid item xs={3} style={{ marginLeft: '20px' }}>
							{' '}
							{/* Adicionei um marginLeft para aumentar o espaço */}
							<Button
								color="primary"
								variant="contained"
								onClick={() => handleUpdateUser(user)}
							>
								Atualizar
							</Button>
						</Grid>
					</Grid>
				))}
			</Paper>
		</Grid>
	);
}

export default UpdateUser;
