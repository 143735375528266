import React, { useState } from 'react';
import {
	Button,
	Grid,
	MenuItem,
	Paper,
	Select,
	Typography,
	FormControl,
	InputLabel,
	TextField,
	FormControlLabel,
	Checkbox,
} from '@mui/material';
import { green } from '@mui/material/colors';
import {
	apiCreateUser,
	// apiCreateUser,
	IClientBackEnd,
	IModuleRoleBackEnd,
	// IUserInput,
	IUserBackEnd,
	IUserInput,
} from '../../../services/apiService';
import { toast } from 'react-toastify';

interface AddUserProps {
	module_roles: IModuleRoleBackEnd[];
	updatedDataToggle: boolean;
	setUpdatedDataToggle: React.Dispatch<React.SetStateAction<boolean>>;
}

function AddUser({
	module_roles,
	updatedDataToggle,
	setUpdatedDataToggle,
}: AddUserProps) {
	const [newUser, setNewUser] = useState<IUserInput>({
		email: '',
		password: '',
		username: '',
		coordinator: false,
		module_role_id: null,
	});

	const handleAddUser = async () => {
		if (
			newUser.email === '' ||
			newUser.password === '' ||
			newUser.username === '' ||
			newUser.email === null
		) {
			toast.warning('Os campos precisam estar preenchidos');
		} else {
			try {
				await apiCreateUser(newUser);
				setNewUser({
					email: '',
					password: '',
					username: '',
					coordinator: false,
					module_role_id: null,
				});
				setUpdatedDataToggle(!updatedDataToggle);
				toast.success('Usuário adicionado com sucesso');
			} catch (error) {
				toast.error('Erro ao adicionar o Usuário');
			}
		}
	};

	return (
		<Grid item xs={12} style={{ marginBottom: 27 }}>
			<Paper elevation={5} style={{ padding: 20 }}>
				<Typography
					variant="h6"
					component="h3"
					style={{ marginBottom: '20px' }}
				>
					Novo Usuário
				</Typography>
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={2}>
						<TextField
							fullWidth
							label="Nome Usuário"
							value={newUser.username}
							onChange={e =>
								setNewUser({ ...newUser, username: e.target.value })
							}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={2}>
						<TextField
							fullWidth
							label="E-mail"
							value={newUser.email}
							onChange={e => setNewUser({ ...newUser, email: e.target.value })}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={2}>
						<TextField
							fullWidth
							label="Senha"
							value={newUser.password}
							onChange={e =>
								setNewUser({ ...newUser, password: e.target.value })
							}
							variant="outlined"
							type="password"
						/>
					</Grid>
					<Grid item xs={1.5}>
						<FormControl fullWidth variant="outlined">
							<InputLabel id="user-select-label">Visualização</InputLabel>
							<Select
								labelId="user-select-label"
								value={newUser.module_role_id}
								onChange={e =>
									setNewUser({
										...newUser,
										module_role_id: Number(e.target.value),
									})
								}
								label="Visualização"
							>
								{module_roles
									.sort((a, b) => a.description.localeCompare(b.description))
									.map(client => (
										<MenuItem key={client.id} value={client.id}>
											{client.description}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={1} style={{ paddingRight: '16px' }}>
						<FormControlLabel
							control={
								<Checkbox
									checked={newUser.coordinator}
									onChange={e =>
										setNewUser({
											...newUser,
											coordinator: e.target.checked,
										})
									}
									name="filterUnscheduled"
									color="primary"
								/>
							}
							label="Coordenador"
						/>
					</Grid>

					<Grid item xs={2} style={{ marginLeft: '16px' }}>
						{' '}
						<Button
							variant="contained"
							onClick={handleAddUser}
							sx={{
								backgroundColor: green[500],
								'&:hover': {
									backgroundColor: green[700],
								},
								color: 'white',
							}}
						>
							<strong>Adicionar</strong>
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	);
}

export default AddUser;
